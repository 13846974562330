import { NextSeo } from 'next-seo';
import { useIntl } from 'react-intl';

import { Section } from '@/core/components/Section';
import { Banner } from '@/core/components/Layout/Banner';
import { navigationMessages } from '@/core/messages/navigation.messages';
import { useLocalizedOGTitle } from '@/core/hooks/useLocalizedOGTitle';

import { api } from '@/features/Homepage/api';
import { HomePageProps } from '@/features/Homepage/homepage.types';
import { fetchConfigRelatedData } from '@/features/Sections/sections.helpers';

import {
  getBackendImage,
  getKeywordsProps,
} from '@/utils/helpers';
import { withNavigationData } from '@/utils/withNavigationData';

const Page = ({ config }: HomePageProps) => {
  const { formatMessage } = useIntl();
  const {
    attributes: {
      sections,
      banner,
      meta,
    },
  } = config;

  const OGTitle = useLocalizedOGTitle(
    meta?.OGTitle || meta?.title || formatMessage(navigationMessages.home)
  );

  return (
    <>
      <NextSeo
        title={meta?.title || formatMessage(navigationMessages.home)}
        description={meta?.description || ''}
        openGraph={{
          description: meta?.OGDescription || meta?.description || '',
          title: OGTitle,
        }}
        {...getKeywordsProps(meta?.keywords)}
      />
      <Banner
        TitleText={banner.header}
        SubtitleText={banner.paragraph}
        Image={getBackendImage(banner.banner.data?.attributes?.url)}
      />
      <div>
        {sections.map(section => (
          <Section
            // eslint-disable-next-line no-underscore-dangle
            key={section.__component}
            {...section}
          />
        ))}
      </div>
    </>
  );
};

export const getServerSideProps = withNavigationData(async context => {
  let config;
  const { locale } = context;

  try {
    const { data: { data } } = await api.getConfig({ locale: context.locale });

    const relatedData = await Promise.all(
      fetchConfigRelatedData(data.attributes.sections, locale)
    );

    relatedData.forEach((item, index) => {
      if (Array.isArray(item?.data?.data)) {
        data.attributes.sections[index].items = item.data.data;
      }
    });
    config = data;
  } catch (error) {
    return {
      redirect: {
        destination: `/${context.locale}/404`,
        permanent: true,
      },
    };
  }

  return {
    props: {
      config,
    },
  };
});

export default Page;
